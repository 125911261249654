const RestApiDefaultParams = {
  data: {
    url: '',
  },
  optionalData: {
    method: 'GET',
    headers: {},
    body: '',
    responseType: 'json'
  },
  callbacks: {
    before: () => undefined,
    after: () => undefined,
  }
}

const restApi = async (params = RestApiDefaultParams) => {
  params ||= {}
  params.callbacks ||= RestApiDefaultParams.callbacks
  params.callbacks ||= RestApiDefaultParams.callbacks
  console.log(`sending request: ${params.data.url}`, params.optionalData)
  params.callbacks.before({ data: params.data, optionalData: params.optionalData })
  const returnValue = {}
  try {
    params.optionalData ||= {}
    const response = await fetch(params.data.url, {
      method: params.optionalData.method || 'GET',
      headers: params.optionalData.headers || {},
      ...(params.optionalData.body ? { body: params.optionalData.body } : {}),
    })
    console.log(`response`, response)
    if (response.statusText !== 'OK' && !response.ok) {
      if (response.headers.get('Content-Type') === 'application/json') {
        const data = await response.json()
        throw data
      } else {
        throw response
      }
    }
    const data = await response[params.optionalData.responseType || 'json']()
    console.log(`recieved response: ${params.data.url}`, data)
    returnValue.success = true
    returnValue.data = data
  } catch (error) {
    const _error = error.data.error || error
    const { message = 'Something went wrong...', stack } = _error
    returnValue.success = false
    returnValue.data = { errorMessage: message, stack, error: _error }
  }
  params.callbacks.after({ data: params.data, optionalData: params.optionalData })
  return returnValue
}

export default restApi