// import axios from 'axios';
// // config
import { HOST_API } from '../config';
import restApi from './restApi'

// ----------------------------------------------------------------------

// const axiosInstance = axios.create({
//   baseURL: HOST_API,
// });

// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );

const axiosInstance = {
  get: (url, options) => restApi({ data: { url } }),
  post: (url, data) => restApi({
    data: { url }, optionalData: {
      body: JSON.stringify(data),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'POST'
    }
  })
}

export default axiosInstance;
